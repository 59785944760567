import React, { useState, useEffect } from "react";

const About = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [hue, setHue] = useState(0);

  const contactEmail = 'katyjwang@gmail.com';
  document.title = `Katy Wang - About`;

  const handleMouseEnter = () => {
    setIsHovering(true);
    setHue(0); // Reset the hue to 0 on hover to change it quickly
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    // Function to gradually update the hue over time
    const updateHue = () => {
      if (!isHovering) {
        // Gradually change the hue when not hovering
        setHue((prevHue) => (prevHue + 1) % 360);
      } else {
        // Quickly change the hue when hovering
        setHue((prevHue) => (prevHue + 20) % 360);
      }
    };

    const interval = setInterval(updateHue, 50); // Update the hue every 50ms

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [isHovering]);

  return (
    <div className="project-page">
      <div className="content-wrapper">
        <div className={`image-column ${isHovering ? "pfphovered" : ""}`}>
          <img
            src="./img/pfp.jpg"
            alt="Profile"
            className="profile-image"
          />
        </div>
        <div className="text-column">
          
          
          <h2 style={{color:"green"}}>KATY WANG</h2> 
          <p style={{ fontSize: "18px" }}>
Award-winning animation director who brings playful characters to life through bold colours and textural brush strokes. Her work plays with the senses in a way that lends an authentically human feel to all of her visuals: a lonely astronaut in outer space; a sleepy bear riding atop a giant dog; elephants dancing and patterns alive - her work is both joyously playful yet weaved with emotion.
</p><p style={{ fontSize: "18px" }}>
During her first year of studying a BA at Kingston School of Art, Katy created 'Mind the Gap', a short film that went on to screen in festivals worldwide and won two awards for ‘best experimental animation’. Later, It's Nice That named her one of its Graduates of 2017 for her graduation film, ‘Contact’. Since then she’s made work for clients such as Headspace, Netflix, Penguin Books, Youtube, Meta, Macmillan, and The New York Times.
</p><p style={{ fontSize: "18px" }}>
She now co-directs with <a href="https://www.gabrielgreenough.co.uk" target="_blank">Gabriel Greenough</a>, working across a multitude of mediums: 2D animation, CG, stop motion, live action.
</p><p style={{ fontSize: "18px" }}>
<div onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>katyjwang@gmail.com</div> <img
          src={isHovering ? "./img/misc/tigeryawn.gif" : " "}
          alt="Profile"
          className="profile-image"
          style={isHovering ? { maxWidth: '120px', visibility: 'visible', marginLeft: '20px' } : { visibility: 'hidden', height: '0', width: '0' }}
        />
        
        
        
</p><p style={{ fontSize: "18px" }}>
<a href="http://www.instagram.com/watykang" target="_blank" style={{ fontStyle: "italic" }}> instagram</a>
</p><p style={{ fontSize: "18px" }}>
<a href="http://www.vimeo.com/katywang" target="_blank" style={{ fontStyle: "italic" }}> vimeo</a>
          </p>
        </div>
      </div>
      <div className="content-wrapper">
      <div className="text-column lowercol">
          
          
          <h3 style={{color:"green"}}>Awards / Recognition</h3> 
		       <p style={{ fontSize: "16px" }}>2022 Vimeo Staff Pick for 'The Natural World, Joy, and Human Flourishing'</p>
		  
     <p style={{ fontSize: "16px" }}>2020 ‘The Peace of Wild Things’ wins The Ron Kovic Peace Prize</p>
	 
	 
<p style={{ fontSize: "16px" }}>2020 Vimeo Staff Pick for 'The Peace of Wild Things'</p>

<p style={{ fontSize: "16px" }}>2020 ‘Pistachio Boy’ wins Best Production Design at The People’s Film Festival</p>

<p style={{ fontSize: "16px" }}>2019 Vimeo Staff Pick for 'AFAR Travel Tales: Round and Round We Go'</p>

<p style={{ fontSize: "16px" }}>2019 ‘Pistachio Boy’ wins Audience Award at Planos Film Festival</p>

<p style={{ fontSize: "16px" }}>2019 Vimeo Staff Pick for 'AFAR Travel Tales: There's No Place like Kansas City'</p>

<p style={{ fontSize: "16px" }}>2019 Vimeo Staff Pick for 'Outer Monologue: Joy Bryant'</p>

<p style={{ fontSize: "16px" }}>2018 ‘Ma Mama’ nominated for the UK Music Video Awards</p>

<p style={{ fontSize: "16px" }}>2018 Vimeo Staff Pick for 'Ma Mama'</p>

<p style={{ fontSize: "16px" }}>2018 Gold Kinsale Shark winner for ‘Ma Mama’ music video</p>

<p style={{ fontSize: "16px" }}>2018 Vimeo Staff Pick for 'Contact'</p>

<p style={{ fontSize: "16px" }}>2017 Motion Awards: Student Group Project winner for 'Contact'</p>

<p style={{ fontSize: "16px" }}>2016 D&AD New Blood winner - Wood pencil</p>

<p style={{ fontSize: "16px" }}>2015 Best Experimental Animation for 'Mind the Gap' (Tofuzi Festival)</p>

<p style={{ fontSize: "16px" }}>2015 Best Abstract Experimental Animation for 'Mind the Gap' (Tindirindis Festival)</p>




        </div>
        <div className="text-column lowercol">
          
          
          <h3 style={{color:"green"}}>Press</h3> 
          <p style={{ fontSize: "16px" }}><a href="https://theaoi.com/varoom/varoom-bottom-blocks/online-exclusive-looking-well/" target="_blank">2021 Varoom The Peace of Wild Things</a></p>

<p style={{ fontSize: "16px" }}><a href="https://www.itsnicethat.com/features/headspace-guide-to-meditation-netflix-animation-150221" target="_blank">2021: It’s Nice That: Headspace</a></p>

<p style={{ fontSize: "16px" }}><a href="https://www.itsnicethat.com/articles/katy-wang-charlotte-ager-the-peace-of-wild-things-animation-271020" target="_blank">2020 It’s Nice That: The Peace of Wild Things</a></p>

<p style={{ fontSize: "16px" }}><a href="http://girlsclub.asia/meet-the-artist/katy-wang/" target="_blank">2019 Girls Club Asia interview</a></p>

<p style={{ fontSize: "16px" }}><a href="https://www.itsnicethat.com/articles/katy-wang-outer-monologues-mailchimp-animation-130919" target="_blank">2019 It’s Nice That: Outer Monologues Mailchimp film</a></p>

<p style={{ fontSize: "16px" }}><a href="https://lectureinprogress.com/journal/katy-wang-creative-confidence" target="_blank">2018 Lecture in Progress: 'Overcoming Creative Anxiety'</a></p>

<p style={{ fontSize: "16px" }}><a href="https://www.itsnicethat.com/articles/katy-wang-yrsa-daley-ward-the-terrible-book-penguin-animation-010618" target="_blank">2018 It's Nice That: Yrsa Daley-Ward Penguin book trailer</a></p>

<p style={{ fontSize: "16px" }}><a href="https://lectureinprogress.com/journal/katy-wang" target="_blank">2018 Lecture in Progress journal interview</a></p>

<p style={{ fontSize: "16px" }}><a href="https://www.itsnicethat.com/articles/katy-wang-toto-bona-lokua-ma-mama-animation-181217?utm_source=twitter&utm_medium=social&utm_campaign=intsocial" target="_blank">2017 It's Nice That: Ma Mama music video</a></p>

<p style={{ fontSize: "16px" }}><a href="https://a-d-o.com/journal/the-graduates-katy-wang/" target="_blank">2017 A/D/O Journal</a></p>

<p style={{ fontSize: "16px" }}><a href="https://lbbonline.com/news/new-talent-katy-wang/" target="_blank">2017 Little Black Book New Talent</a></p>

<p style={{ fontSize: "16px" }}><a href="http://www.itsnicethat.com/features/katy-wang-animation-the-graduates-2017-060717" target="_blank">2017 It's Nice That Graduate of the Year</a></p>

        </div>
		 <div className="text-column lowercol">
          
          
          <h3 style={{color:"green"}}>Workshops, talks & lectures</h3> 
		  <p style={{ fontSize: "16px" }}>2023 Visiting lecturer at Norwich University of the Arts</p>
		  <p style={{ fontSize: "16px" }}>2022 All Ears Talk at Animade studio</p>
		   <p style={{ fontSize: "16px" }}>2022 Lecture and tutorials for level 3 Illustration Animation students at Kingston School of Art</p>
		   	  <p style={{ fontSize: "16px" }}>2021 Lecture and tutorials at University of Creative Arts Canterbury</p>
			   <p style={{ fontSize: "16px" }}>2021 Lecture at University Arts London</p>
		  <p style={{ fontSize: "16px" }}>2020 Screenskills x She Drew That panel discussion</p>
<p style={{ fontSize: "16px" }}>2019 Visiting lecturer for Hyper Island Motion Design students in Karlskrona, Sweden</p>

<p style={{ fontSize: "16px" }}>2018 Visiting lecturer for 3rd year Illustration & Graphic Design students at Sheffield Institute of Arts</p>

<p style={{ fontSize: "16px" }}>2018 D&AD Festival: Pitch Futures</p>

<p style={{ fontSize: "16px" }}>2018 BFI Future Film Festival Panel Discussion: 'Finding Your Visual Voice'</p>

<p style={{ fontSize: "16px" }}>2017 Talk at A/D/O, Brooklyn, NY</p>
</div>
      </div>
    </div>
  );
};

export default About;
