import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import Navbar from './components/index.js';
import { BrowserRouter as Router, Routes, Route, useLocation }
    from 'react-router-dom';
import { animated, useTransition, config, easings} from 'react-spring';
import smoothscroll from 'smoothscroll-polyfill'; 
import About from './pages/about';
import Work from './pages/work';
import MiscWork from './pages/misc.js';
import Homepage from './pages/homepage';
import Contact from './pages/contact';
import ProjectPage from './components/ProjectPage';
import NotFound from './components/NotFound';


function App() {
    // Call smoothscroll polyfill before rendering the component
    smoothscroll.polyfill();

    return (
        <Router>
            <div className="navbar-container">
                <Navbar />
            </div>
            <Routes>
                <Route path="/*" element={<AppRoutes />} />
            </Routes>
        </Router>
    );
}

function AppRoutes() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    // Use useEffect to scroll to the top whenever location changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    //    document.body.style.backgroundColor = isHomePage ? 'black' : '#FDF2E9'; // Use smooth scrolling to the top
    }, [location]);

    const transitions = useTransition(location, {
        from: { opacity: 0, transform: 'translateY(-2%)', position: 'absolute', width: '100%' },
        enter: { opacity: 1, transform: 'translateY(0%)', position: 'absolute', width: '100%' },
        leave: { opacity: 0, transform: 'translateY(-5%)', position: 'absolute', width: '100%' },
        config: { tension: 370, friction: 26, mass: 1, duration: 600, easing: easings.easeInOutQuart },
        opacity: easings.easeInOutQuart,
    });

    
      

    return (
        <div className="transition-container">
            {transitions((styles, item) => (
                <animated.div style={styles}>
                    <Routes location={item}>
                        <Route path="/" element={<Work />} />
                        {/* Route path="/oldhomepage" element={<Homepage />} /> */}
                        <Route path="/about" element={<About />} />
                        <Route path="/work/:projectId" element={<ProjectPage />} />
                        <Route path="/misc" element={<MiscWork />} />
                        <Route path="/misc/:projectId" element={<ProjectPage />} />
                        {/* <Route path="/contact" element={<Contact />} /> */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </animated.div>
            ))}
        </div>
    );
}

export default App;
