import React, { useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import DOMPurify from 'dompurify';
import WorkProjectPage from '../components/ProjectPage';
import workData from '../components/work.json'; // Import the JSON data

const Work = () => {
  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
  };

  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.remove("no-scroll");
    document.body.style.overflow = 'visible'; // Apply the body overflow style when the component mounts
  }, []);

  // Filter out projects that are flagged as "old"
  const filteredWorkData = workData.filter((entry) => !entry.isOld);

  const isVideoFile = (fileName) => {
    const videoExtensions = ['.mp4', '.mov', '.webm']; // Add more video extensions if needed
    return videoExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  };

  const isSafariOrIOS = /iP(hone|od|ad)|Macintosh|Safari|Instagram|FBAN|FBAV|LinkedIn/i.test(navigator.userAgent) && !/Chrome|Android|Firefox/i.test(navigator.userAgent);


  const convertVideoToGif = (videoUrl) => {
    // Replace the video extension with .gif
    return videoUrl.replace(/\.[^.]+$/, '.gif');
  };

  document.title = `Katy Wang`;

  return (
    <div className="work-page">
      <div className="work-grid">
        {filteredWorkData.map((entry, index) => (
          <Link
            to={`/work/${entry.URL}`}
            key={index}
            className="project-thumbnail"
          >
            <div className="thumbnail-overlay">
              <h2
                className="thumbnail-title"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(entry.projectTitle) }}
              ></h2>
            </div>
            {isSafariOrIOS ? (
              isVideoFile(entry.thumbnailImage) ? (
                <img className="thumbnail-image" src={convertVideoToGif(entry.thumbnailImage)} alt={entry.projectTitle} />
              ) : (
                <img className="thumbnail-image" src={entry.thumbnailImage} alt={entry.projectTitle} />
              )
            ) : isVideoFile(entry.thumbnailImage) ? (
              <video className="thumbnail-video" src={entry.thumbnailImage} autoPlay loop muted playsInline />
            ) : (
              <img className="thumbnail-image" src={entry.thumbnailImage} alt={entry.projectTitle} />
            )}
          </Link>
        ))}
      </div>
  
      <Routes>
        <Route path="/work/:projectId" element={<WorkProjectPage />} />
      </Routes>
    </div>
  );
};

export default Work;
