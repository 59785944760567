import React from "react";
import { useLocation } from "react-router-dom";
import {
  Nav,
  NavLink,
  NavContainer,
  Bars,
  NavMenu,
  Logo
} from "./NavBarElements.js";

const Navbar = () => {
  const { pathname } = useLocation();

  // Check if the current path is the homepage
  const isHomePage = pathname === '/';
  

  return (
    <>
      <Nav >
        <NavContainer >
          <NavLink to="/" activeStyle>
          <img
            src="/img/misc/oilpastelsquare.gif"
            alt="colourful oil pastel animation"
            className="logoicon"
          />
            <Logo><img
            src="/img/logo.jpg"
            alt="Katy Wang"
            className="logo"
          /></Logo>
          </NavLink>
        </NavContainer>
        <NavMenu>
          <NavLink to="/" activeStyle className={isHomePage ? 'homepage-nav' : ''}>
            work
          </NavLink>
          <NavLink to="/about" activeStyle className={isHomePage ? 'homepage-nav' : ''}>
            about/contact
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;