import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Carousel from 'better-react-carousel';
import UnsafeworkData from '../components/work.json';

const sanitizeWorkData = () => {
  return UnsafeworkData.map((entry) => {
    const sanitizedEntry = { ...entry };

    // Sanitize description fields using DOMPurify and strip HTML tags
    const descriptionKeys = ['description1', 'description2'];
    descriptionKeys.forEach((key) => {
      if (sanitizedEntry[key]) {
        sanitizedEntry[key] = DOMPurify.sanitize(sanitizedEntry[key], { ALLOWED_TAGS: [] });
      }
    });

    // Sanitize videoEmbed and video2Embed arrays
    const videoEmbedKeys = ['videoEmbed', 'video2Embed'];
    videoEmbedKeys.forEach((key) => {
      if (Array.isArray(sanitizedEntry[key])) {
        sanitizedEntry[key] = sanitizedEntry[key].map((embed) =>
          DOMPurify.sanitize(embed, { ALLOWED_TAGS: [] })
        );
      }
    });

    return sanitizedEntry;
  });
};

const ProjectPage = () => {
  const [workData, setWorkData] = useState(sanitizeWorkData());
  const { projectId } = useParams();
  const project = workData.find((entry) => entry.URL === projectId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!project) {
      navigate('/404');
    } else if (projectId === 'misc') {
      navigate('/misc');
    } else {
      if (project.isOld) {
        const targetURL = `/misc/${projectId}`;
        if (window.location.pathname !== targetURL) {
          navigate(targetURL);
        }
      } else {
        const targetURL = `/work/${projectId}`;
        if (window.location.pathname !== targetURL) {
          navigate(targetURL);
        }
      }

      document.title = `Katy Wang - ${project.projectTitle}`;
    }
  }, [projectId, project, navigate]);

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageViewerAnimation, setImageViewerAnimation] = useState('');

  const openImageViewer = (index) => {
    setCurrentImageIndex(index);
    setIsImageViewerOpen(true);
    document.body.classList.add('no-scroll');
    setImageViewerAnimation('fade-in');

    setTimeout(() => {
      setImageViewerAnimation('');
    }, 300);
  };

  const closeImageViewer = () => {
    setImageViewerAnimation('fade-out');

    setTimeout(() => {
      setIsImageViewerOpen(false);
      document.body.classList.remove('no-scroll');
    }, 300);
  };

  

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const addLineBreaksAndLinks = (description) => {
    // Replace BIGTEXTSTART and BIGTEXTEND with <h2> tags
    let formattedDescription = description.replace(
      /BIGTEXTSTART\s*(.*?)\s*BIGTEXTEND/g,
      '<h2>$1</h2>'
    );

    // Replace LINEBREAK with <br />
    formattedDescription = formattedDescription.replace(/LINEBREAK/g, '<br />');

    // Replace LINKBEGIN and LINKEND with <a> tags
    const linkRegex = /LINKBEGIN\s*(.*?)\s*LINKEND/g;
    formattedDescription = formattedDescription.replace(
      linkRegex,
      '<a href="http://$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    const italicRegex = /ITALICSTART\s*(.*?)\s*ITALICEND/g;
  formattedDescription = formattedDescription.replace(
    italicRegex,
    '<em>$1</em>'
  );

    return formattedDescription;
  };

  const isVideoFile = (fileName) => {
    const videoExtensions = ['.mp4', '.mov', '.webm']; // Add more video extensions if needed
    return videoExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const convertVideoToGif = (videoUrl) => {
    // Replace the video extension with .gif
    return videoUrl.replace(/\.[^.]+$/, '.gif');
  };

  const getEmbedUrls = (videoEmbed) => {
    if (!videoEmbed) {
      return [];
    } else if (Array.isArray(videoEmbed)) {
      
  
      return videoEmbed.map((embed) => {
        const fileExtension = embed.substring(embed.lastIndexOf('.') + 1).toLowerCase();
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
        const isVideo = ['mov', 'mp4', 'mp5', 'webm'].includes(fileExtension);
  
        if (isImage) {
          return { type: 'image', src: embed }; // Flag for image link
        } else if (isVideo) {
          if (isIOS) {
            // For iOS devices, convert video URLs to GIF
            return { type: 'image', src: convertVideoToGif(embed) };
          } else {
            return { type: 'video', src: embed }; // Flag for video link
          }
        } else if (embed.includes('vimeo.com')) {
          const parts = embed.split('/');
          const hasPrivateKey = parts.length > 4;
  
          if (hasPrivateKey) {
            const videoId = parts[parts.length - 2];
            const privateKey = parts[parts.length - 1].split('/')[0];
            return `https://player.vimeo.com/video/${videoId}?h=${privateKey}&autoplay=1&muted=1`;
          } else {
            const videoId = parts[parts.length - 1];
            if (!isNaN(videoId)) {
              return `https://player.vimeo.com/video/${videoId}?autoplay=1&muted=1`;
            }
          }
        } else if (embed.includes('youtube.com')) {
          const videoId = embed.split('v=')[1];
          return `https://www.youtube.com/embed/${videoId}`;
        }
        return null;
      }).filter(embedUrl => embedUrl !== null); // Filter out null entries
    } else if (videoEmbed.includes('youtube.com')) {
      const videoId = videoEmbed.split('v=')[1];
      return [`https://www.youtube.com/embed/${videoId}`];
    } else if (videoEmbed.includes('vimeo.com')) {
      const parts = videoEmbed.split('/');
      const videoId = parts[parts.length - 1];
      return [`https://player.vimeo.com/video/${videoId}`];
    } else {
      return [];
    }
  };
  
  
  

  
  
  
  
  
  
  
  
  
  
  

  if (!project) {
    return null;
  }

  const getCarouselConfig = (index) => {
    const colsKey = `Carousel${index}Cols`;
    const rowsKey = `Carousel${index}Rows`;
    const arrowEnabledKey = `Carousel${index}ArrowEnabled`;
    const responsiveLayoutKey = `Carousel${index}ResponsiveLayout`;
    const mobileBreakpointKey = `Carousel${index}MobileBreakpoint`;
  
    const config = {
      cols: project[colsKey] || 2,
      rows: project[rowsKey] || 1,
      arrowEnabled: project[arrowEnabledKey] !== undefined ? project[arrowEnabledKey] : true,
      responsiveLayout: project[responsiveLayoutKey] || [],
      mobileBreakpoint: project[mobileBreakpointKey] || 767,
    };
  
    console.log("Carousel Config for index", index, ":", config);
  
    return config;
  };
  
  
  
  

  const getAdditionalVideoEmbeds = () => {
    const additionalVideos = [];
  
    for (let i = 2; i <= 3; i++) {
      const videoEmbedKey = `video${i}Embed`;
  
      if (project[videoEmbedKey]) {
        const videoUrls = getEmbedUrls(project[videoEmbedKey]);
  
        videoUrls.forEach((videoUrl, index) => {
          let mediaElement = null;
          let containerClass = "";
  
          if (typeof videoUrl === 'object' && videoUrl.type === 'image') {
            mediaElement = <img style={{ width: '100%' }} src={videoUrl.src} alt={`Image ${i}`} />;
            containerClass = "projectimagecontainer";
          } else if (typeof videoUrl === 'object' && videoUrl.type === 'video') {
            if (isIOS) {
              // For iOS devices, convert video to GIF
              mediaElement = <img style={{ width: '100%' }} src={convertVideoToGif(videoUrl.src)} alt={`Converted Video to GIF ${i}`} />;
            } else {
              mediaElement = (
                <video
                  className="projectvideocontainer"
                  src={videoUrl.src}
                  title={`Additional Video Embed ${i}`}
                  autoPlay
                  loop
                  muted
                  playsInline
                  key={index}
                >
                  Your browser does not support the video tag.
                </video>
              );
              containerClass = "projectvideocontainer";
            }
          } else {
            mediaElement = (
              <iframe
                className="projectvideocontainer"
                title={`Additional Video Embed ${i}`}
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                key={index}
              ></iframe>
            );
            containerClass = "projectvideocontainer";
          }
  
          additionalVideos.push(
            <div key={`${videoEmbedKey}-${index}`} className={containerClass}>
              {mediaElement}
            </div>
          );
        });
      }
    }
  
    return additionalVideos;
  };
  
  
  
  

  const renderDescriptionsAndCarousels = () => {
    const elements = [];
    for (let i = 1; i <= 4; i++) {
      const descriptionKey = `description${i}`;
      const carouselKey = `Carousel${i}`;
      const carouselTitleKey = `Carousel${i}Title`;

      if (project[descriptionKey]) {
        elements.push(
          <React.Fragment key={descriptionKey}>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(addLineBreaksAndLinks(sanitizeHTML(project[descriptionKey]))),
              }}
            ></p>
            {i === 1 && getAdditionalVideoEmbeds()}
          </React.Fragment>
        );
      }

  
      // Inside renderDescriptionsAndCarousels function
      if (project[carouselKey]) {
        const { cols, rows, arrowEnabled, responsiveLayout, mobileBreakpoint } = getCarouselConfig(i);
        const carouselData = project[carouselKey];
        const carouselTitle = project[carouselTitleKey] || `Carousel ${i}`;
      
        elements.push(
          <div key={carouselKey} className="carousel-wrapper">
            <h3 className="carousel-title">{carouselTitle}</h3>
            <Carousel
              cols={cols}
              rows={rows}
              gap={10}
              loop
              hideArrow={!arrowEnabled}
              responsiveLayout={responsiveLayout} // Add this line
              mobileBreakpoint={mobileBreakpoint} // Add this line
            >
              {Array.isArray(carouselData) && carouselData.map((link, index) => {
                const fileExtension = link.substring(link.lastIndexOf('.') + 1).toLowerCase();
                const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
                const isVideo = ['mov', 'mp4', 'mp5', 'webm'].includes(fileExtension);
      
                return (
                  <Carousel.Item key={index}>
  {isImage ? (
    <img style={{ maxHeight: '80vh', maxWidth: '100%', display: 'block', margin: '0 auto' }} src={link} alt={`Carousel Image ${index + 1}`} />
  ) : isVideo ? (
    isIOS ? (
      // For iOS devices, convert video to GIF
      <img style={{ width: '100%' }} src={convertVideoToGif(link)} alt={`Converted Video to GIF ${index + 1}`} />
    ) : (
      <video width="100%" height="100%" autoPlay loop muted playsInline>
        <source src={link} type={`video/${fileExtension}`} />
        Your browser does not support the video tag.
      </video>
    )
  ) : null}
</Carousel.Item>

                );
              })}
            </Carousel>
          </div>
        );
      }
      

    }
    return elements;
  }; 
  
  

  

  const videoUrls = getEmbedUrls(project.videoEmbed);
  const imageLinks = project.imageLinks || [];
  

  const calculateImageStyles = (image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image;
  
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const maxWidthPercentage = 100 / imageLinks.length;
  
        resolve({
          width: `calc(${maxWidthPercentage}% - 1rem)`,
          height: `calc((100% * ${aspectRatio}) - 1rem)`,
          objectFit: 'contain',
          cursor: 'pointer',
        });
      };
  
      img.onerror = reject;
    });
  };
  

  const renderAdditionalImages = () => {
    const ImageCols = window.innerWidth < 768 ? 1 : project.ImageCols || 2;
  
    return (
      <div className="additional-images" style={{ gridTemplateColumns: `repeat(${ImageCols}, 1fr)` }}>
        {imageLinks.map((link, index) => {
          const isClickable = Array.isArray(project.imageClickable)
            ? project.imageClickable[index]
            : project.imageClickable;
  
          const fileExtension = link.substring(link.lastIndexOf('.') + 1).toLowerCase();
          const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
          const isVideo = ['mov', 'mp4', 'mp5', 'webm'].includes(fileExtension);
  
          if (isImage) {
            const imageStyles = calculateImageStyles(link); // Calculate image styles
            return (
              <div key={index} className={`image-container`} >
                <img src={link} alt={`Additional Image ${index + 1}`} style={imageStyles} />
              </div>
            );
          } else if (isVideo) {
            
            const videoElement = isIOS ? (
              // For iOS devices, use GIF instead of video
              <img src={convertVideoToGif(link)} alt={`Additional Image (Converted from Video) ${index + 1}`} />
            ) : (
              <video width="100%" height="100%" autoPlay loop muted playsInline>
                <source src={link} type={`video/${fileExtension}`} />
                Your browser does not support the video tag.
              </video>
            );
  
            return (
              <div key={index} className={`image-container non-clickable`}>
                {videoElement}
              </div>
            );
          }
  
          return null; // Render nothing for unsupported file types
        })}
      </div>
    );
  };
  
  
  
  
  

  
  return (
    <div className="project-page">
      <div className="proj-page-title-container">
        <div className="back-button">
          <Link to={window.location.pathname.includes('/misc/') ? '/misc' : '/'}>
            <i className="fas fa-arrow-left"></i>
          </Link>
        </div>
        <h2 className="proj-heading">{project.projectTitle}</h2>
      </div>

      {videoUrls.map((embedUrl, index) => {
  if (typeof embedUrl === 'object') {
    // Check if it's an image or a video
    return embedUrl.type === 'image' ? (
        <img style={{ width:'100%'}} src={embedUrl.src} alt={`Image ${index + 1}`} />
      
    ) : (
      <div key={index}>
        <video
        className="projectvideocontainer"
          src={embedUrl.src}
          title={`Video Embed ${index + 1}`}
          autoPlay
          loop
          muted
          playsInline
        >
          Your browser does not support the video tag.
        </video>
      </div>
    );
  } else {
    return (
      <div key={index} className="projectvideocontainer">
        <iframe
          className="projectvideocontainer"
          title={`Video Embed ${index + 1}`}
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  }
})}

      {renderDescriptionsAndCarousels()}

      {renderAdditionalImages()}

      
          <Link to={window.location.pathname.includes('/misc/') ? '/misc' : '/'}>
  <p></p>
  <i className="fas fa-arrow-left" style={{ fontSize: '24px' }}></i>
</Link>

        

    

      {isImageViewerOpen && (
        <div className={`image-viewer ${imageViewerAnimation}`} onClick={closeImageViewer}>
          <img src={imageLinks[currentImageIndex]} alt="Full-size Image" />
          <div className="close-button" onClick={closeImageViewer}>
            <i className="fas fa-times" style={{ height: '163px' }}></i>
          </div>
        </div>
      )}

      
    </div>
  );
};

export default ProjectPage;
